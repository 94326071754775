/* eslint-disable react-hooks/exhaustive-deps */
import RouteComponent from "./Router";
import "./App.css";
import UserContext from "./contextApi/UserContext";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  useEffect(() => {
    autoLogin();
  }, []);

  const [islogin, setIsLogin] = useState(false);
  const [loader, setLoader] = useState(false);
  const [merchantData, setMerchantData] = useState();
  const handelLogin = (arg) => {
    setIsLogin(arg);
  };

  const handleLoader = (arg) => {
    setLoader(arg);
  };

  const autoLogin = async () => {
    const token = await localStorage.getItem("merchant_token");
    if (token) {
      setIsLogin(true);
    } else {
      return;
    }
  };

  return (
    <UserContext.Provider
      value={{
        islogin,
        loginFunc: handelLogin,
        loader,
        handleLoader,
        setMerchantData,
        merchantData,
      }}
    >
       <ToastContainer
        position="top-center"
        autoClose={3500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <RouteComponent />
    </UserContext.Provider>
  );
}

export default App;
