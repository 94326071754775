import { useContext, useRef, useState } from "react";
import UserContext from "../../contextApi/UserContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ForsaLogo from "../../assets/forsaLogo3.png";
import qrImg from "../../assets/qrcode.png";
import Button from "@material-ui/core/Button";
import { CgSpinner } from "react-icons/cg";
import Popup from "reactjs-popup";
import { useLocation, useHistory } from "react-router-dom";
import "./PosStyle.scss";
import QRCode from "react-qr-code";
import { UserUtility } from "../../components/utilities/UserUtility";
import { toast } from "react-toastify";
export default function QrTransaction() {
  const { merchantData } = useContext(UserContext);
  const location = useLocation();
  const [spinner, setSpinner] = useState(false);
  const ref = useRef();
  const history = useHistory();
  const qrResult = history?.location?.state?.qrResult;
  const uniqueOrder = history?.location?.state?.uniqueOrder;
  console.log("qr: ", qrResult?.qr);

  const handleSubmit = () => {
    UserUtility.getPosTransaction()
      .then((res) => {
        let findObj = res.results.find((item) => {
          return item.gateway_order_id == uniqueOrder;
        });
        console.log("findObj: ", findObj);
        if (findObj) {
          confirmHandle(findObj);
        } else {
          toast.error(
            "Please scan QR code firstly to complete the transaction process"
          );
        }
      })
      .catch((err) => {
        console.log("err of getPosTrans: ", err);
      });
  };

  const confirmHandle = (item) => {
    let obj = {
      gateway_trx_id: item.gateway_trx_id,
      confirmed: true,
    };
    UserUtility.confirmTransaction(item.id, obj)
      .then((res) => {
        console.log("res of confirm: ", res);

        history.push({
          pathname: `/Pos/transactions/${item.id}`,
          state: { transaction: item },
        });
      })
      .catch((err) => {
        console.log("err of confirmTransaction: ", err);
      });
  };
  return (
    <div className="login_container">
      <div className="image_container" style={{ width: "42%", padding: 0 }}>
        {/* <img alt="qr_image" src={qrImg} className="qr_style" /> */}
        {/* Can be anything instead of `maxWidth` that limits the width. */}
        <div>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={qrResult?.qr}
            viewBox={`0 0 256 256`}
          />
          <p className="code_p">Code: {qrResult?.qr_alternative}</p>
        </div>
      </div>
      <div className="sec2">
        <div style={{ width: "100%" }}>
          <Button
            className="btn"
            type="submit"
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Confirm the transaction
          </Button>
        </div>

        <div style={{ width: "100%", marginTop: 16 }}>
          <Button
            className="btn"
            type="submit"
            variant="contained"
            onClick={() => history.goBack()}
          >
            Retrun back
          </Button>
        </div>
      </div>
    </div>
  );
}
