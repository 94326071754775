import { useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ForsaLogo from "../../assets/forsaLogo3.png";
import Button from "@material-ui/core/Button";
import { CgSpinner } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import { UserUtility } from "../../components/utilities/UserUtility";
import Popup from "reactjs-popup";

export default function ForgotPassword() {
  const [spinner, setSpinner] = useState(false);
  const [targetError, setTargetError] = useState("");
  let history = useHistory();
  const ref = useRef();
  const openTooltip = () => ref.current.open();

  const validateYup = Yup.object({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const handleSubmit = (values) => {
    setSpinner(true);
    UserUtility.forgetPassword(values)
      .then((res) => {
        setSpinner(false);
        history.push({
          pathname: "/Verification",
          state: { email: values.email },
        });
      })
      .catch((error) => {
        let errorEmail = error ? (error.email ? error.email[0] : "") : "";
        setTargetError(errorEmail);
        console.log(error);
        openTooltip();
        setSpinner(false);
      });
  };

  return (
    <div>
      <div className="login_container forgetPassword_container">
        <div className="image_container">
          <div className="image_wrapper">
            <img alt="logo_image" src={ForsaLogo} className="image_wrapper" />
          </div>
          <h4>Merchant App</h4>
        </div>
        <div className="form_container">
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validateYup}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className="form">
                <article className="inputs_wrapper email_wrapper">
                  <h4 className="login_header">Forgot Password?</h4>
                  <section className="inputs">
                    <div className="input-container">
                      <label className="form_label">Email</label>
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className="input"
                      />
                      {errors.email && touched.email ? (
                        <div className="error_message">{errors.email}</div>
                      ) : null}
                    </div>
                  </section>
                </article>
                <Popup
                  ref={ref}
                  trigger={<span style={{ height: 0 }}></span>}
                  className="popup"
                  position={"top left"}
                >
                  <div className="pop"> {targetError} </div>
                </Popup>
                <article className="buttonsWrapper">
                  <Button
                    className="login_button"
                    type="submit"
                    variant="contained"
                    disabled={errors.email || !values.email ? true : false}
                    endIcon={spinner ? <CgSpinner className="spinner" /> : null}
                  >
                    Send Code
                  </Button>
                </article>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
