import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { UserUtility } from "../../components/utilities/UserUtility";
import UserContext from "../../contextApi/UserContext";
import ForsaLogo from "../../assets/forsaLogo.png";


export default function Nav() {

  const navButtonsAr = [
    { title: "General View", path: "/" },
    { title: "Transaction Details", path: "/Transaction" },
    { title: "Refund", path: "/Refund" },
    { title: "Pos", path:"/LoginPos" },
    // { title: "Settlement", path: "/Settlement" },
  ];
  const [navBarButtons] = useState(navButtonsAr);
  const [merchantDataa, setMerchantDataa] = useState(null);  
  const { loginFunc, setMerchantData } = useContext(UserContext);

  useEffect(() => {
    getMerchantData();
  }, []);

  const logoutFunc = () => {
    localStorage.removeItem("merchant_token");
    localStorage.removeItem("Pos_token");
    localStorage.removeItem("admin_mail");
    loginFunc(false);
  };

  const getMerchantData = () => {
    UserUtility.merchantData()
      .then((res) => {
        setMerchantData(res);
        setMerchantDataa(res);
      })
      .catch((err) => {
        console.error("failed to get merchant data", err);
      });
  };

  return (
    <>
      <ul className="nav">
        <li className="logo">
          <img alt="logo" src={ForsaLogo} />
        </li>
        <h6 className="store_name">
          {merchantDataa
            ? merchantDataa.brand_name || "Brand name"
            : "Brand name"}
        </h6>
        <h5 className="user_name">
          {merchantDataa
            ? merchantDataa.merchant_name || "Merchant name"
            : "Merchant name"}
        </h5>
        {navBarButtons.map((res, index) => {
          return (
            <li key={index} className="nav_li">
              <NavLink exact={true} className="nav_link" to={res.path}>
                {res.title}
              </NavLink>
            </li>
          );
        })}
        <li className="nav_li">
          <Link
            to={{
              pathname: "/Export",
              state: {
                profile: merchantDataa,
              },
            }}
            className="nav_link"
          >
            Export
          </Link>
        </li>
        <li className="nav_li">
          <Link to="/" onClick={logoutFunc} className="nav_link">
            Logout
          </Link>
        </li>
      </ul>
    </>
  );
}
