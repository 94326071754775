import { useHistory, useParams } from "react-router-dom";
import commaNumber from "comma-number";
import Button from "@material-ui/core/Button";
import "../../components/transactionComponent/TransactionComponent.scss";
export default function PosTransactionDetail() {
  const {id} = useParams();
  const history = useHistory();
  const transaction = history?.location?.state?.transaction;
  let productFullDate = new Date(transaction.created_date);
  // to get product date
  let productYearDate = productFullDate.getFullYear();
  let productMonthDate = productFullDate.getMonth() + 1;
  let productDayDate = productFullDate.getDate();

  console.log("transactionnnnn: ", transaction);
  //to get product time
  let actualProductHoursDate = productFullDate.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  return (
    <section className="transaction_main_sec">
      <article className="applications">
        <article className="transaction_customer_details_container">
          <section className="purchaseNum_wrapper">
            <div className="transaction_date">
              <h5 className="transaction_date_time">
                {transaction.created_time}
              </h5>

              <h5>
                {productDayDate +
                  "/" +
                  productMonthDate +
                  "/" +
                  productYearDate}
              </h5>
            </div>
          </section>
        </article>
        {transaction.loans.map((loan, index) => {
          return (
            <article
              className={
                loan.refunded
                  ? "transaction_product_details_container transactionRefunded"
                  : "transaction_product_details_container"
              }
              key={index}
            >
              <section className="product_name">
                <h2>{loan.product_label}</h2>
              </section>

              <section className="product_name" style={{ width: "30%" }}>
                <h2>
                  Principal:
                  {" EGP " + commaNumber(Math.trunc(loan.principal))}
                </h2>
              </section>

              <section>
                <div className="dflex aCenter">
                  <h4 className="bill_amount">
                    Total due amount:
                    {"EGP " + commaNumber(Math.trunc(loan.total_due_amount))}
                  </h4>
                </div>
              </section>

              <section className="purchaseNum_wrapper">
                <div className="dflex aCenter">
                  {loan.refunded ? (
                    <Button
                      className="refunded_bunton"
                      type="submit"
                      variant="contained"
                      disabled={true}
                    >
                      Refunded
                    </Button>
                  ) : null}
                </div>
              </section>
            </article>
          );
        })}

        {/* {console.log('transaction: ',transaction)} */}
        <article className="fees_amount">
          <div className="feesDetails">
            <h3>Total amount:</h3>
            <h4>
              {transaction.total_amount
                ? "EGP " + commaNumber(Math.trunc(transaction.total_amount))
                : "EGP 0"}
            </h4>
            <div className="rightSpace"></div>
          </div>
          <div className="feesDetails">
            <h3>Total admin fees:</h3>
            <h4>
              {transaction.total_fees
                ? "EGP " + commaNumber(Math.trunc(transaction.total_fees))
                : "EGP 0"}
            </h4>
            <div className="rightSpace"></div>
          </div>
          <div className="feesDetails">
            <h3>Total down payment:</h3>
            <h4>
              {transaction.total_down_payment
                ? "EGP " +
                  commaNumber(Math.trunc(transaction.total_down_payment))
                : "EGP 0"}
            </h4>
            <div className="rightSpace"></div>
          </div>
          <div className="feesDetails">
            <h3>Total due amount:</h3>
            <h4>
              {"EGP " +
                commaNumber(
                  Math.trunc(
                    transaction.total_down_payment + transaction.total_fees
                  )
                )}
            </h4>
            <div className="rightSpace"></div>
          </div>
        </article>
        {/* <article className="transactionComponent_net_tansaction_container">
          <section className="net_tansaction_wrapper">
            <div className="net_tansaction">
              <h3>Net transaction :</h3>
              <h5>
                {transaction.net_transaction
                  ? "EGP " +
                    commaNumber(Math.trunc(transaction.net_transaction))
                  : "EGP 0"}
              </h5>
            </div>
            
            <div style={{ display: "flex", alignItems: "center" }}>
              {transaction?.liability_settled ? (
                <CgCheckR
                  style={{
                    color: "#41b6a8",
                  }}
                />
              ) : (
                <CgCloseR
                  style={{
                    color: "red",
                  }}
                />
              )}
              <h6>setteled</h6>
            </div>
          </section>
        </article> */}
      </article>
    </section>
  );
}
