import React, { useContext } from "react";
import UserContext from "../../contextApi/UserContext";
import { CgSpinner } from "react-icons/cg";

const LoaderComponent = () => {
  const { loader } = useContext(UserContext);

  return (
    <div
      className={loader ? "loader_container " : "loader_container removeLoader"}
    >
      <CgSpinner className="spinner_loader" size={200} />
    </div>
  );
};

export default LoaderComponent;
