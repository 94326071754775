import { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ForsaLogo from "../../assets/forsaLogo3.png";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { CgSpinner } from "react-icons/cg";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { UserUtility } from "../../components/utilities/UserUtility";

export default function ResetPassword() {
  const [spinner, setSpinner] = useState(false);
  const [visibility, setVisibility] = useState(false);

  const validateYup = Yup.object({
    new_password: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*\d)(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, at least one Number, upperCase, lowercase and one special Character "
      ),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Required"),
  });
  let history = useHistory();
  const otp = history.location.state ? history.location.state.otp : " ";

  const handleSubmit = (values) => {
    setSpinner(true);
    UserUtility.resetPassword(values, otp)
      .then((res) => {
        setSpinner(false);
        history.push({
          pathname: "/PasswordChanged",
        });
      })
      .catch((error) => {
        console.error(error);
        setSpinner(false);
      });
  };
  return (
    <div>
      <div className="login_container">
        <div className="image_container">
          <div className="image_wrapper">
            <img alt="logo_image" src={ForsaLogo} className="image_wrapper" />
          </div>
          <h4>Merchant App</h4>
        </div>
        <div className="form_container">
          <Formik
            initialValues={{ new_password: "", confirm_new_password: "" }}
            validationSchema={validateYup}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form className="form">
                <article className="inputs_wrapper">
                  <h4 className="login_header">Reset Password</h4>
                  <section className="inputs">
                    <div className="input-container">
                      <label className="form_label">New Password</label>
                      <div className="password_wrapper">
                        <input
                          type={visibility ? "text" : "password"}
                          name="new_password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.new_password}
                          className="input password_input"
                        />
                        <div
                          className="eye_icon"
                          onClick={() => setVisibility(!visibility)}
                        >
                          {visibility ? <BsEye /> : <BsEyeSlash />}
                        </div>
                      </div>
                      {errors.new_password && touched.new_password ? (
                        <div className="error_message">
                          {errors.new_password}
                        </div>
                      ) : null}
                    </div>

                    <div className="input-container">
                      <label className="form_label">
                        Re-enter New Password
                      </label>
                      <div className="password_wrapper">
                        <input
                          type={visibility ? "text" : "password"}
                          name="confirm_new_password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirm_new_password}
                          className="input password_input"
                        />
                        <div
                          className="eye_icon"
                          onClick={() => setVisibility(!visibility)}
                        >
                          {visibility ? <BsEye /> : <BsEyeSlash />}
                        </div>
                      </div>
                      {errors.confirm_new_password &&
                      touched.confirm_new_password ? (
                        <div className="error_message">
                          {errors.confirm_new_password}
                        </div>
                      ) : null}
                    </div>
                  </section>
                </article>
                <article className="buttonsWrapper">
                  <Button
                    className="login_button"
                    type="submit"
                    variant="contained"
                    disabled={
                      errors.confirm_new_password ||
                      errors.new_password ||
                      !values.new_password ||
                      !values.confirm_new_password
                        ? true
                        : false
                    }
                    endIcon={spinner ? <CgSpinner className="spinner" /> : null}
                  >
                    Reset Password
                  </Button>
                  {/* <button type="submit">ds</button> */}
                </article>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
