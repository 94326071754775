import { useContext, useRef, useState } from "react";
import UserContext from "../../contextApi/UserContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ForsaLogo from "../../assets/forsaLogo3.png";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { CgSpinner } from "react-icons/cg";
import { UserUtility } from "../../components/utilities/UserUtility";
import Popup from "reactjs-popup";
import { useLocation, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function Login() {
  const user = useContext(UserContext);
  const location = useLocation();
  const posLogin = location?.pathname === "/LoginPos";
  let PosToken = localStorage.getItem('Pos_token');
  let adminMail = localStorage.getItem('admin_mail');
  const [spinner, setSpinner] = useState(false);
  const ref = useRef();
  let history = useHistory();

  console.log(PosToken)
  console.log(adminMail)

  const openTooltip = () => ref.current.open();
  const handleLogin = async (values) => {
    setSpinner(true);
    UserUtility.login(values)
      .then((res) => {
        localStorage.setItem("merchant_token", res.token);
        user.loginFunc(true);
        setSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        openTooltip();
        setSpinner(false);
      });
  };

  useEffect(() => {
    if (PosToken !== null) {
      history.push({
        pathname: `/Pos/detail`,
      });
    }
  }, [PosToken, history])

  const handlePosLogin = async (values) => {
    setSpinner(true);
    UserUtility.pos_login(values)
      .then((res) => {
        console.log("res", res);
        localStorage.setItem("Pos_token", res.token);
        // user.loginFunc(true);
        setSpinner(false);
        history.push({
          pathname: `/Pos/detail`,
        });
      })
      .catch((error) => {
        console.error("pos_login", error);
        // openTooltip();
        setSpinner(false);
        const er = error?.errors?.non_field_errors[0]
        toast.error(er)
      });
  };

  const validateYup = Yup.object({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  return (
    <div className="login_container">
      <div className="image_container">
        <div className="image_wrapper">
          <img alt="logo_image" src={ForsaLogo} className="image_wrapper" />
        </div>
        <h4>Merchant App</h4>
      </div>
      <div className="form_container" style={{ marginLeft: posLogin && "20%" }}>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validateYup}
          // onSubmit={(values, { setSubmitting }) =>
          //   handelLogin(values, setSubmitting)
          // }
          onSubmit={async (values) => {
            const newValues = { ...values, admin_mail: `${adminMail}` }
            console.log(newValues)
            if (posLogin) {
              handlePosLogin(newValues)
            } else {
              handleLogin(values);
              localStorage.setItem("admin_mail", values.email);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="form">
              <article className="inputs_wrapper">
                <h4 className="login_header">Login</h4>
                <section className="inputs">
                  <div className="input-container">
                    <label className="form_label">Email</label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="input"
                    />
                    {errors.email && touched.email ? (
                      <div className="error_message">{errors.email}</div>
                    ) : null}
                  </div>

                  <div className="input-container">
                    <label className="form_label">Password</label>
                    <input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      className="input"
                    />
                    {errors.password && touched.password ? (
                      <div className="error_message">{errors.password}</div>
                    ) : null}
                  </div>
                </section>
              </article>
              {/* <p className="pop">Email or Password is incorrect </p> */}
              <Popup
                ref={ref}
                trigger={<span style={{ height: 0 }}></span>}
                className="popup"
                position={"top left"}
              >
                <div className="pop"> Email or Password is incorrect </div>
              </Popup>
              <article className="buttonsWrapper">
                <Button
                  className="login_button"
                  type="submit"
                  variant="contained"
                  disabled={
                    errors.password ||
                      errors.email ||
                      !values.email ||
                      !values.password
                      ? true
                      : false
                  }
                  endIcon={spinner ? <CgSpinner className="spinner" /> : null}
                >
                  Login
                </Button>
                {!posLogin && (
                  <Link to="/ForgotPassword" className="forget_password">
                    Forgot password
                  </Link>
                )}
              </article>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
