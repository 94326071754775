import { ENDPOINTS } from "../../apis/EndPoints";
import { Network } from "../../apis/NetWork";

export class UserUtility {
  static generalView(pageNumber, search_by_liability_date) {
    return Network.fetch(
      ENDPOINTS.generalView.url(pageNumber, search_by_liability_date),
      {
        method: ENDPOINTS.generalView.method,
      },
      true
    );
  }

  static transactionDetails(
    start_date,
    end_date,
    branch,
    pageNumber,
    search_by_liability_date
  ) {
    return Network.fetch(
      ENDPOINTS.transactionDetails.url(
        start_date,
        end_date,
        branch,
        pageNumber,
        search_by_liability_date
      ),
      {
        method: ENDPOINTS.transactionDetails.method,
      },
      true
    );
  }

  static settlement() {
    return Network.fetch(
      ENDPOINTS.settlement.url,
      {
        method: ENDPOINTS.settlement.method,
      },
      true
    );
  }

  static refund_transaction_view() {
    return Network.fetch(
      ENDPOINTS.refund_transaction_view.url,
      {
        method: ENDPOINTS.refund_transaction_view.method,
      },
      true
    );
  }

  static refund_transaction_view_byId(userId) {
    return Network.fetch(
      ENDPOINTS.refund_transaction_view_byId.url(userId),
      {
        method: ENDPOINTS.refund_transaction_view_byId.method,
      },
      true
    );
  }

  static refund_transaction_view_byPhone(userPhone) {
    return Network.fetch(
      ENDPOINTS.refund_transaction_view_byPhone.url(userPhone),
      {
        method: ENDPOINTS.refund_transaction_view_byPhone.method,
      },
      true
    );
  }

  static otpToUser(loanId) {
    return Network.fetch(
      ENDPOINTS.otpToUser.url,
      {
        body: JSON.stringify({ refund_loan: loanId }),
        method: ENDPOINTS.otpToUser.method,
      },
      true
    );
  }

  static otpFromUser(reqNumber, otpNumber) {
    return Network.fetch(
      ENDPOINTS.otpFromUser.url,
      {
        body: JSON.stringify({ refund_request: reqNumber, otp: otpNumber }),
        method: ENDPOINTS.otpFromUser.method,
      },
      true
    );
  }

  static login(values) {
    let userCredentials = {
      username: `${values.email}`,
      password: values.password,
    };
    return Network.fetch(
      ENDPOINTS.login.url,
      {
        body: JSON.stringify(userCredentials),
        method: ENDPOINTS.login.method,
      },
      false
    );
  }

  static forgetPassword(values) {
    let userCredentials = {
      email: `${values.email}`,
    };
    return Network.fetch(
      ENDPOINTS.forgetPassword.url,
      {
        body: JSON.stringify(userCredentials),
        method: ENDPOINTS.forgetPassword.method,
      },
      false
    );
  }

  static verificationCode(values) {
    let userCredentials = {
      email: `${values.email}`,
      otp: `${values.otp}`,
    };
    return Network.fetch(
      ENDPOINTS.verificationCode.url,
      {
        body: JSON.stringify(userCredentials),
        method: ENDPOINTS.verificationCode.method,
      },
      false
    );
  }

  static resetPassword(values, otp) {
    let userCredentials = {
      otp: `${otp}`,
      new_password: `${values.new_password}`,
      confirm_new_password: `${values.confirm_new_password}`,
    };
    return Network.fetch(
      ENDPOINTS.resetPassword.url,
      {
        body: JSON.stringify(userCredentials),
        method: ENDPOINTS.resetPassword.method,
      },
      false
    );
  }

  static merchantData() {
    return Network.fetch(
      ENDPOINTS.merchantData.url,
      {
        method: ENDPOINTS.merchantData.method,
      },
      true
    );
  }

  static exportTransations(start_date, end_date, branch) {
    return Network.fetch(
      ENDPOINTS.exportTransactions.url(start_date, end_date, branch),
      {
        method: ENDPOINTS.exportTransactions.method,
      },
      true
    );
  }

  // POS
  static pos_login(values) {
    let userCredentials = {
      username: `${values.email}`,
      password: values.password,
      admin_mail: values.admin_mail,
    };
    return Network.fetch(
      ENDPOINTS.pos_login.url,
      {
        body: JSON.stringify(userCredentials),
        method: ENDPOINTS.pos_login.method,
      },
      false
    );
  }

  static async generateQR(values) {
    let posToken = await localStorage.getItem("Pos_token");
    console.log("posToken: ", posToken);
    return Network.fetch(
      ENDPOINTS.qr.url,
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.qr.method,
      },
      true,
      posToken
    );
  }

  static async getPosTransaction(params) {
    let posToken = await localStorage.getItem("Pos_token");

    return Network.fetch(
      ENDPOINTS.posTrans.url(params),
      {
        method: ENDPOINTS.posTrans.method,
      },
      true,
      posToken
    );
  }

  static async confirmTransaction(id, values) {
    let posToken = await localStorage.getItem("Pos_token");

    return Network.fetch(
      ENDPOINTS.confirmTrans.url(id),
      {
        body: JSON.stringify(values),
        method: ENDPOINTS.confirmTrans.method,
      },
      true,
      posToken
    );
  }
}
