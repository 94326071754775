/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { UserUtility } from "../../components/utilities/UserUtility";
import TransactionComponent from "../../components/transactionComponent/TransactionComponent";
import UserContext from "../../contextApi/UserContext";

export default function GeneralView() {
  const [customerHistory, setCustomerHistory] = useState({});
  const [count, setCount] = useState(1);
  const { handleLoader } = useContext(UserContext);

  const handleCounter = () => {
    if (customerHistory.next !== null && customerHistory.next.length > 10) {
      setCount(count + 1);
    }
  };
  useEffect(() => {
    getTransactionDetails();
  }, [count]);

  const getTransactionDetails = () => {
    handleLoader(true);

    UserUtility.generalView(count, false)
      .then((res) => {
        if (count !== 1) {
          customerHistory.results.push(...res.results);
          customerHistory.next = res.next;
        } else {
          setCustomerHistory(res);
        }
        handleLoader(false);
      })
      .catch((err) => {
        console.error("error", err);
        handleLoader(false);
      });
  };
  return (
    <TransactionComponent
      transactionData={customerHistory}
      handleCounter={handleCounter}
    />
  );
}
