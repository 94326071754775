/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Button from "@material-ui/core/Button";
import commaNumber from "comma-number";
import moment from "moment";
import { CgCheckR, CgCloseR } from "react-icons/cg";

export default function TransactionComponent(props) {
  const {
    transactionData,
    handleCounter,
    details,
    start_date = "All",
    end_date = "All",
    liability_date,
  } = props;
  return (
    <div className="Refund transactionComponent_container">
      <article className="transactionComponent_amounts">
        <div className="transactionComponent_total colorGreen">
          <section className="total_wrapper">
            <h4>Total</h4>
            <h4>Bill Amount</h4>
            <div className="applications">
              <h2>
                EGP <br />
                {transactionData.summary?.total_bill_amount
                  ? commaNumber(
                    Math.trunc(transactionData.summary.total_bill_amount)
                  )
                  : "0"}
              </h2>
            </div>
          </section>
        </div>
        <div className="transactionComponent_total colorRed">
          <section className="total_wrapper">
            <h4>Total</h4>
            <h4>Admin Fees</h4>
            <div className="applications">
              <h2>
                EGP <br />
                {transactionData.summary?.total_admin_fees
                  ? commaNumber(
                    Math.trunc(transactionData.summary.total_admin_fees)
                  )
                  : "0"}
              </h2>
            </div>
          </section>
        </div>
        <div className="transactionComponent_total colorRed">
          <section className="total_wrapper">
            <h4>Total</h4>
            <h4>Processing Fees</h4>
            <div className="applications">
              <h2>
                EGP <br />
                {transactionData.summary?.total_processing_fees
                  ? commaNumber(
                    Math.trunc(transactionData.summary.total_processing_fees)
                  )
                  : "0"}
              </h2>
            </div>
          </section>
        </div>
        <div className="transactionComponent_total colorRed">
          <section className="total_wrapper">
            <h4>Total</h4>
            <h4>Forsa Kick back</h4>
            <div className="applications">
              <h2>
                EGP <br />
                {transactionData.summary?.total_forsa_commission
                  ? commaNumber(
                    Math.trunc(transactionData.summary.total_forsa_commission)
                  )
                  : "0"}
              </h2>
            </div>
          </section>
        </div>
        <div className="transactionComponent_total colorBlack">
          <section className="total_wrapper">
            <h4>Net</h4>
            <h4>Transactions</h4>
            <div className="applications">
              <h2>
                EGP <br />
                {transactionData.summary?.net_transactions
                  ? commaNumber(
                    Math.trunc(transactionData.summary.net_transactions)
                  )
                  : "0"}
              </h2>
            </div>
          </section>
        </div>
      </article>
      <div className="search_resualt_container">
        {liability_date ? (
          <>
            <h4 className="search_header result_date_label">Liability Date:</h4>
            <span className="result_date">
              {moment(liability_date).format("DD-MM-YYYY")}
            </span>
          </>
        ) : details ? (
          <>
            <h4 className="search_header result_date_label">from</h4>
            <span className="result_date">
              {start_date === "Invalid date" ? "All" : start_date}
            </span>
            <h4 className="search_header result_date_label">to</h4>
            <span className="result_date">
              {end_date === "Invalid date" ? "All" : end_date}
            </span>
          </>
        ) : (
          <h4 className="search_header">Customer History</h4>
        )}

        {/* customer history refund */}
        {Object.keys(transactionData).length
          ? transactionData.results.map((eachHistory, index) => {
            let productFullDate = new Date(eachHistory.created);
            //to get product date
            let productYearDate = productFullDate.getFullYear();
            let productMonthDate = productFullDate.getMonth() + 1;
            let productDayDate = productFullDate.getDate();

            //to get product time
            let actualProductHoursDate = productFullDate.toLocaleString(
              "en-US",
              {
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
              }
            );
            return (
              <article className="applications" key={index}>
                <article className="transaction_customer_details_container">
                  {/* <section className="customer_details">
                      <div className="dflex aCenter">
                      <h3>Customer name :</h3>
                      <h4>{eachHistory.customer_name}</h4>
                      </div>
                      <div className="dflex aCenter">
                        <h3>Customer phone :</h3>
                        <h4>{eachHistory.customer_phone}</h4>
                      </div>
                    </section> */}
                  <section className="branch_wrapper">
                    <div className="dflex aCenter">
                      <h3>Branch :</h3>
                      <h4>{eachHistory.branch_Name}</h4>
                    </div>
                  </section>
                  <div className="purchase_num">
                    <h3>Purchase No. :</h3>
                    <h4>{eachHistory.purchase_number}</h4>
                  </div>
                  <div className="purchase_num">
                    <h3>Tenor :</h3>
                    <h4>{eachHistory.products[0].tenor}</h4>
                  </div>
                  <section className="purchaseNum_wrapper">
                    <div className="transaction_date">
                      <h5 className="transaction_date_time">
                        {actualProductHoursDate}
                      </h5>

                      <h5>
                        {productDayDate +
                          "/" +
                          productMonthDate +
                          "/" +
                          productYearDate}
                      </h5>
                    </div>
                  </section>
                </article>
                {eachHistory.products.map((product, index) => {
                  return (
                    <article
                      className={
                        product.refunded
                          ? "transaction_product_details_container transactionRefunded"
                          : "transaction_product_details_container"
                      }
                      key={index}
                    >
                      <section className="product_name">
                        <h2>{product.product_label}</h2>
                      </section>

                      <section className="product_name" style={{ width: '30%' }}>
                        <h2>Down payment:
                          {" EGP " +
                            commaNumber(Math.trunc(product.down_payment))}
                        </h2>
                      </section>

                      <section>
                        <div className="dflex aCenter">
                          <h4 className="bill_amount">
                            {"EGP " +
                              commaNumber(Math.trunc(product.bill_amount))}
                          </h4>
                        </div>
                      </section>

                      <section className="category_name">
                        <h2>{product.category_title}</h2>
                      </section>
                      <section className="purchaseNum_wrapper">
                        <div className="dflex aCenter">
                          {product.refunded ? (
                            <Button
                              className="refunded_bunton"
                              type="submit"
                              variant="contained"
                              disabled={true}
                            >
                              Refunded
                            </Button>
                          ) : null}
                        </div>
                      </section>
                    </article>
                  );
                })}
                {/* {console.log('eachHistory: ',eachHistory)} */}
                <article className="fees_amount">
                  <div className="feesDetails">
                    <h3>Bill amount:</h3>
                    <h4>
                      {eachHistory.bill_amount
                        ? "EGP " +
                        commaNumber(Math.trunc(eachHistory.bill_amount))
                        : "EGP 0"}
                    </h4>
                    <div className="rightSpace"></div>
                  </div>
                  <div className="feesDetails">
                    <h3>Admin fees:</h3>
                    <h4>
                      {eachHistory.admin_fees
                        ? "EGP " +
                        commaNumber(Math.trunc(eachHistory.admin_fees))
                        : "EGP 0"}
                    </h4>
                    <div className="rightSpace"></div>
                  </div>
                  <div className="feesDetails">
                    <h3>Payment Fees:</h3>
                    <h4>
                      {eachHistory.processing_fees
                        ? "EGP " +
                        commaNumber(Math.trunc(eachHistory.processing_fees))
                        : "EGP 0"}
                    </h4>
                    <div className="rightSpace"></div>
                  </div>
                  <div className="feesDetails">
                    <h3>Forsa Kick back:</h3>
                    <h4>
                      {eachHistory.forsa_commission
                        ? "EGP " +
                        commaNumber(
                          Math.trunc(eachHistory.forsa_commission)
                        )
                        : "EGP 0"}
                    </h4>
                    <div className="rightSpace"></div>
                  </div>

                  {/* downpayment */}
                  {console.log('eachHistory?.down_payments: ', eachHistory?.down_payments)}
                  <div className="feesDetails">
                    <h3>Down payment:</h3>
                    <h4>
                      {"EGP " +
                        commaNumber(
                          Math.trunc(eachHistory?.down_payments)
                        )}
                    </h4>
                    <div className="rightSpace"></div>
                  </div>
                </article>
                <article className="transactionComponent_net_tansaction_container">
                  <section className="net_tansaction_wrapper">
                    <div className="net_tansaction">
                      <h3>Net transaction :</h3>
                      <h5>
                        {eachHistory.net_transaction
                          ? "EGP " +
                          commaNumber(
                            Math.trunc(eachHistory.net_transaction)
                          )
                          : "EGP 0"}
                      </h5>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {eachHistory?.liability_settled ? (
                        <CgCheckR
                          style={{
                            color: "#41b6a8",
                          }}
                        />
                      ) : (
                        <CgCloseR
                          style={{
                            color: "red",
                          }}
                        />
                      )}
                      <h6>setteled</h6>
                    </div>
                  </section>
                </article>
              </article>
            );
          })
          : null}
        <div className="view_More_container">
          <Button className="view_More" onClick={handleCounter}>
            View More
          </Button>
        </div>
      </div>
    </div>
  );
}
