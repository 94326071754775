import ForsaLogo from "../../assets/forsaLogo3.png";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import DoneImage from "../../assets/Icon awesome-check-circle.svg";

export default function PasswordChanged() {
  let history = useHistory();

  const handleSubmit = () => {
    history.push({
      pathname: "/",
    });
  };

  return (
    <div className="login_container">
      <div className="image_container">
        <div className="image_wrapper">
          <img alt="logo_image" src={ForsaLogo} className="image_wrapper" />
        </div>
        <h4>Merchant App</h4>
      </div>
      <div className="form_container">
        <div className="form">
          <article className="inputs_wrapper" style={{ alignItems: "center" }}>
            <h4 className="login_header">Reset Password</h4>
            <section className="inputs doneImage_wrapper">
              <div className="input-container">
                <div className="doneImage">
                  <img src={DoneImage} alt="done" />
                </div>
              </div>
              <p>Password Changed Successfully</p>
            </section>
          </article>
          <article className="buttonsWrapper">
            <Button
              className="login_button"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Login
            </Button>
          </article>
        </div>
      </div>
    </div>
  );
}
