import { useRef, useState } from "react";
import ForsaLogo from "../../assets/forsaLogo3.png";
import Button from "@material-ui/core/Button";
import { CgSpinner } from "react-icons/cg";
import ReactCodeInput from "react-verification-code-input";
import { useHistory } from "react-router-dom";
import { UserUtility } from "../../components/utilities/UserUtility";
import Popup from "reactjs-popup";

export default function Verification() {
  const [spinner, setSpinner] = useState(false);
  const [verCode, setVerCode] = useState("");
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  let history = useHistory();

  const getEmail = history.location.state ? history.location.state.email : " ";

  const handleSubmit = (values) => {
    values.preventDefault();
    setSpinner(true);
    const verificationValues = { email: getEmail, otp: verCode };

    UserUtility.verificationCode(verificationValues)
      .then((res) => {
        setSpinner(false);
        history.push({
          pathname: "/ResetPassword",
          state: { otp: verCode },
        });
      })
      .catch((error) => {
        // let errorEmail = error.email ? error.email[0] : "";
        // setTargetError(errorEmail);
        console.error(error);
        setSpinner(false);
        openTooltip();
      });
  };

  const handleVerCode = (code) => {
    setVerCode(code);
  };
  return (
    <div>
      <div className="login_container forgetPassword_container">
        <div className="image_container">
          <div className="image_wrapper">
            <img alt="logo_image" src={ForsaLogo} className="image_wrapper" />
          </div>
          <h4>Merchant App</h4>
        </div>
        <div className="form_container">
          <form className="form" onSubmit={handleSubmit}>
            <article className="inputs_wrapper email_wrapper">
              <h4 className="login_header">Verification Code</h4>
              <section className="inputs">
                <div className="input-container">
                  <ReactCodeInput
                    onChange={handleVerCode}
                    // style={{ with: "100%" }}
                    className="verification_code"
                    fields={5}
                  />
                </div>
              </section>
            </article>
            <Popup
              ref={ref}
              trigger={<span style={{ height: 0 }}></span>}
              className="popup"
            >
              <div className="pop"> Code entered is incorrect </div>
            </Popup>
            <article className="buttonsWrapper">
              <Button
                className="login_button"
                type="submit"
                variant="contained"
                disabled={verCode.length === 5 ? false : true}
                endIcon={spinner ? <CgSpinner className="spinner" /> : null}
              >
                Submit
              </Button>
            </article>
          </form>
        </div>
      </div>
    </div>
  );
}
