import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { FaCheckCircle } from "react-icons/fa";
import { UserUtility } from "../../components/utilities/UserUtility";
import { useHistory, useParams } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { ToastContainer, toast } from "react-toastify";

export default function Otp() {
  const [otpCheck, setOtpCheck] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [spinner, setSpinner] = useState(false);
  const { item } = useParams();
  const history = useHistory();
  const phone = history.location.state.phone;

  const handleOtp = (otpNumber) => {
    setOtpCode(otpNumber.target.value);

    if (otpNumber.target.value.length === 4) {
      setOtpCheck(true);
    } else {
      setOtpCheck(false);
    }
  };
  const handleSubmit = () => {
    setSpinner(true);

    UserUtility.otpFromUser(item, otpCode)
      .then((res) => {
        setSpinner(false);
        setOtpSent(true);
      })
      .catch((err) => {
        setSpinner(false);
        console.error("error", err);
        toast.error("error occured, please contact us");
      });
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="transaction_container otp_container">
        <div className="search_resualt_container">
          {otpSent ? (
            <div className="transaction_cancelled_container">
              <div className="checkIcon">
                <FaCheckCircle style={{ width: "56px", height: "56px" }} />
              </div>
              <h4 className="search_header transaction_cancelled">
                Refund has been completed successfully
              </h4>
            </div>
          ) : (
            <>
              {phone ? (
                <h4 className="search_header">
                  OTP Sent to <span> {phone}</span>
                </h4>
              ) : (
                <h4 className="search_header">{}</h4>
              )}

              <div className="searchBar_wrapper">
                <input
                  className="search_input"
                  onChange={handleOtp}
                  type="text"
                  placeholder="OTP"
                />
                <Button
                  className="searchButton"
                  type="submit"
                  variant="contained"
                  disabled={!otpCheck}
                  onClick={() => handleSubmit()}
                  endIcon={spinner ? <CgSpinner className="spinner" /> : null}
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
