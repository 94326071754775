/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import { RiErrorWarningLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { UserUtility } from "../../components/utilities/UserUtility";
import UserContext from "../../contextApi/UserContext";
import commaNumber from "comma-number";

export default function Refund() {
  const [refundId, setRefundId] = useState(false);
  const [checkPhone, setCheckPhone] = useState(false);
  const [phoneNumber, setphoneNumber] = useState("01");
  const [idNumber, setIdNumber] = useState("01");
  const [checkIdNumber, setCheckIdNumber] = useState(false);
  const [customerHistory, setCustomerHistory] = useState([]);
  const [search, setSearch] = useState(false);
  const { handleLoader } = useContext(UserContext);
  let history = useHistory();

  useEffect(() => {
    checkIdAndPhone();
  }, [refundId]);

  const getRefundById = () => {
    handleLoader(true);

    UserUtility.refund_transaction_view_byId(idNumber)
      .then((res) => {
        setCustomerHistory(res.results);
        handleLoader(false);
      })
      .catch((err) => {
        console.error("error", err);
        handleLoader(false);
      });
  };
  const getRefundByPhone = () => {
    handleLoader(true);

    UserUtility.refund_transaction_view_byPhone(phoneNumber)
      .then((res) => {
        setCustomerHistory(res.results);
        handleLoader(false);
      })
      .catch((err) => {
        console.error("error", err);
        handleLoader(false);
      });
  };
  //to check if phone and id are valid
  const checkIdAndPhone = () => {
    if (phoneNumber.length === 14 && refundId === true) {
      setCheckIdNumber(true);
      setCheckPhone(false);
    } else if (phoneNumber.length === 13 && refundId === false) {
      setCheckPhone(true);
    } else {
      setCheckPhone(false);
    }
  };

  const handleSearch = (e) => {
    let searchNumber = e.target.value;
    setIdNumber(searchNumber);
    const letterNumber = /^[0-9]+$/;
    if (searchNumber.match(letterNumber)) {
      setphoneNumber(searchNumber);
      if (searchNumber.length === 14 && refundId === true) {
        setCheckIdNumber(true);
      } else if (searchNumber.length === 11 && refundId === false) {
        setCheckPhone(true);
        setphoneNumber("+2" + searchNumber);
      } else {
        setCheckPhone(false);
        setCheckIdNumber(false);
      }
    } else setCheckPhone(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getRefundByPhone();
    setSearch(true);
  };
  const handleSubmitId = (e) => {
    e.preventDefault();
    getRefundById();
    setSearch(true);
  };
  const sendOtpToUser = (loanId, customer_phone) => {
    UserUtility.otpToUser(loanId)
      .then((res) => {
        history.push({
          pathname: `/Refund/${res.detail.id}`,
          state: { phone: customer_phone },
        });
      })
      .catch((err) => {
        console.error("error", err);
      });
  };
  return (
    <div className="Refund">
      <div className="searchBar_container">
        <div className="toggle_wrapper">
          <button
            className={refundId ? "Refund_phone" : "Refund_id"}
            onClick={() => {
              setRefundId(true);
            }}
          >
            ID
          </button>
          <button
            className={refundId ? "Refund_id" : "Refund_phone"}
            onClick={() => {
              setRefundId(false);
            }}
          >
            Phone
          </button>
        </div>
        {refundId ? (
          <>
            <form className="searchBar_wrapper" onSubmit={handleSubmitId}>
              <input
                className="search_input"
                onChange={handleSearch}
                type="text"
              />
              <Button
                className="searchButton"
                type="submit"
                variant="contained"
                disabled={!checkIdNumber}
                // endIcon={spinner ? <CgSpinner className="spinner" /> : null}
              >
                Search
              </Button>
            </form>
          </>
        ) : (
          <>
            <form className="searchBar_wrapper" onSubmit={handleSubmit}>
              <input
                className="search_input"
                onChange={handleSearch}
                type="text"
              />
              <Button
                className="searchButton"
                type="submit"
                variant="contained"
                disabled={!checkPhone}
                // endIcon={spinner ? <CgSpinner className="spinner" /> : null}
              >
                Search
              </Button>
            </form>
          </>
        )}
      </div>
      {search && customerHistory.length ? (
        <div className="search_resualt_container">
          <h4 className="search_header">Customer History</h4>

          {customerHistory.length
            ? customerHistory.map((eachHistory, index) => {
                let productFullDate = new Date(eachHistory.created);
                //to get product date
                let productYearDate = productFullDate.getFullYear();
                let productMonthDate = productFullDate.getMonth() + 1;
                let productDayDate = productFullDate.getDate();

                //to get product time
                let actualProductHoursDate = productFullDate.toLocaleString(
                  "en-US",
                  {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: true,
                  }
                );
                return (
                  <article
                    className={
                      eachHistory.refundable
                        ? "applications"
                        : "applications notRefundable"
                    }
                    key={index}
                  >
                    <article className="refund_customer_details_container">
                      <section className="customer_details">
                        <div className="dflex aCenter">
                          <h3>Customer name :</h3>
                          <h4>{eachHistory.customer_name}</h4>
                        </div>
                        <div className="dflex aCenter">
                          <h3>Customer phone :</h3>
                          <h4>{eachHistory.customer_phone}</h4>
                        </div>
                      </section>
                      <section className="branch_wrapper">
                        <div className="dflex aCenter">
                          <h3>Branch :</h3>
                          <h4>{eachHistory.branch_Name}</h4>
                        </div>
                      </section>
                      <section className="purchaseNum_wrapper">
                        <div className="dflex aCenter fEnd">
                          <h3>Purchase No. :</h3>
                          <h4>{eachHistory.purchase_number}</h4>
                        </div>
                        <div className="transaction_date">
                          <h5 className="transaction_date_time">
                            {actualProductHoursDate}
                          </h5>

                          <h5>
                            {productDayDate +
                              "/" +
                              productMonthDate +
                              "/" +
                              productYearDate}
                          </h5>
                        </div>
                      </section>
                    </article>
                    {eachHistory.products.map((product, index) => {
                      return (
                        <article
                          className={
                            product.refunded
                              ? "transaction_product_details_container transactionRefunded"
                              : "transaction_product_details_container "
                          }
                          key={index}
                        >
                          <section className="product_name">
                            <h2>{product.product_label}</h2>
                          </section>
                          <section>
                            <div className="dflex aCenter">
                              <h4 className="bill_amount">
                                {"EGP " +
                                  commaNumber(Math.trunc(product.bill_amount))}
                              </h4>
                            </div>
                          </section>
                          <section className="purchaseNum_wrapper">
                            <div className="dflex aCenter">
                              <Button
                                className="refunded_bunton"
                                variant="contained"
                                disabled={
                                  eachHistory.refundable === false ||
                                  product.refunded === true
                                    ? true
                                    : false
                                }
                                onClick={() =>
                                  sendOtpToUser(
                                    product.id,
                                    eachHistory.customer_phone
                                  )
                                }
                              >
                                {product.refunded ? "Refunded" : "Refund"}
                              </Button>
                            </div>
                          </section>
                        </article>
                      );
                    })}
                    <article className="fees_amount">
                      <div className="feesDetails">
                        <h3>Bill amount:</h3>
                        <h4>
                          {eachHistory.bill_amount
                            ? "EGP " +
                              commaNumber(Math.trunc(eachHistory.bill_amount))
                            : "0"}
                        </h4>
                        <div className="rightSpace"></div>
                      </div>
                      <div className="feesDetails">
                        <h3>Forsa commission:</h3>
                        <h4>
                          {eachHistory.forsa_commission
                            ? "EGP " +
                              commaNumber(
                                Math.trunc(eachHistory.forsa_commission)
                              )
                            : "0"}
                        </h4>
                        <div className="rightSpace"></div>
                      </div>
                      <div className="feesDetails">
                        <h3>Admin fees:</h3>
                        <h4>
                          {" "}
                          {eachHistory.admin_fees
                            ? "EGP " +
                              commaNumber(Math.trunc(eachHistory.admin_fees))
                            : "0"}
                        </h4>
                        <div className="rightSpace"></div>
                      </div>
                    </article>
                    <article className="net_tansaction_container">
                      <section className="net_tansaction">
                        <h3>Net transaction :</h3>
                        <h5>
                          {eachHistory.net_transaction
                            ? "EGP " +
                              commaNumber(
                                Math.trunc(eachHistory.net_transaction)
                              )
                            : "0"}
                        </h5>
                      </section>
                      <section className="net_tansaction">
                        {eachHistory.refundable ? (
                          <></>
                        ) : (
                          <>
                            <RiErrorWarningLine className="warning_icon" />
                            <h6 className="notRefundableText">
                              This Transaction is NOT refundable
                            </h6>
                          </>
                        )}
                      </section>
                    </article>
                  </article>
                );
              })
            : null}
        </div>
      ) : null}
    </div>
  );
}
