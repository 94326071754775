export class Network {
  constructor() {
    this.jwt = "";
  }

  static async fetch(url, init, addAuth, exist_token) {
    this.jwt = await localStorage.getItem("merchant_token");

    const response = await fetch(url, {
      mode: "cors",
      ...init,
      headers: Network.getHeaders(init.headers, addAuth, exist_token),
    });
    let promise;

    if (response.status !== 200 && response.status !== 201) {
      promise = Network.handleErrorsBasedOnStatus(response);
    } else {
      promise = response.json();
    }
    return promise;
  }

  static getHeaders(originalHeaders, addAuth, exist_token) {
    let headers = {
      "content-type": "application/json",
      accept: "application/json",
    };

    if (addAuth) {
      headers.Authorization = `token ${exist_token ? exist_token : this.jwt}`;
    }

    headers = {
      ...headers,
      ...originalHeaders,
    };

    return headers;
  }

  static handleErrorsBasedOnStatus(response) {
    let promise;

    switch (response.status) {
      case 400:
        promise = response.json().then((data) => {
          return Promise.reject(data);
        });

        break;
      case 422:
        promise = response.json().then((data) => {
          return Promise.reject(data);
        });
        break;
      case 429:
        promise = response.json().then((data) => {
          return Promise.reject(data);
        });
        break;
      case 401:
      case 403:
        promise = response.json().then((data) => {
          return Promise.reject(data);
        });
        break;
      case 204:
        promise = response;
        return promise;

      default:
        promise = response.json().then((data) => {
          return Promise.reject(data);
        });
    }

    return promise.catch((error) => {
      return Promise.reject(error);
    });
  }
}
