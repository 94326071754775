import { useContext, useRef, useState } from "react";
import UserContext from "../../contextApi/UserContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ForsaLogo from "../../assets/forsaLogo3.png";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { CgSpinner } from "react-icons/cg";
import { UserUtility } from "../../components/utilities/UserUtility";
import Popup from "reactjs-popup";
import { useLocation, useHistory } from "react-router-dom";
import "./PosStyle.scss";
export default function PosDetail() {
  const { merchantData } = useContext(UserContext);
  const location = useLocation();
  const posLogin = location?.pathname === "/LoginPos";
  const [spinner, setSpinner] = useState(false);
  const ref = useRef();
  let history = useHistory();

  const validateYup = Yup.object({
    amount: Yup.number()
      .required("Amount is required")
      .typeError("Must be digit number"),

    note: Yup.string().notRequired(),
  });

  const handleSubmit = (values) => {
    console.log("handleSubmit function");

    let uniqueNum = Math.floor(
      Math.random() * Math.floor(Math.random() * Date.now())
    );
    console.log("uniqueNum: ", uniqueNum);
    console.log("merchantData: ", merchantData.brand_name);
    let uniqueOrder = merchantData.brand_name + uniqueNum;
    let obj = {
      amount: values.amount,
      gateway_order_id: uniqueOrder,
    };
    UserUtility.generateQR(obj)
      .then((res) => {
        console.log("res of generateQR: ", res);

        history.push({
          pathname: `/Pos/transaction`,
          state: { qrResult: res, uniqueOrder: uniqueOrder },
        });
      })
      .catch((err) => {
        console.log("err of generateQR: ", err);
      });
  };
  return (
    <div className="login_container">
      <div className="image_container">
        <div className="image_wrapper">
          <img alt="logo_image" src={ForsaLogo} className="image_wrapper" />
        </div>
        <h4>Merchant App</h4>
      </div>
      <div className="form_container" style={{ marginLeft: posLogin && "20%" }}>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validateYup}
          // onSubmit={(values, { setSubmitting }) =>
          //   handelLogin(values, setSubmitting)
          // }
          onSubmit={async (values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="pos_form">
              <article className="inputs_wrapper">
                <h4 className="login_header">Details</h4>
                <section className="inputs">
                  <div className="input-container">
                    <label className="form_label">
                      Enter the invoice value
                    </label>
                    <input
                      type="number"
                      name="amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount}
                      className="input"
                    />
                    {errors.amount && touched.amount ? (
                      <div className="error_message">{errors.amount}</div>
                    ) : null}
                  </div>

                  {/* <div className="input-container" style={{ marginTop: 20 }}>
                    <label className="form_label">Add Notes ( optional )</label>
                    <textarea
                      name="note"
                      rows="6"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.note}
                      className="textarea_class"
                    />
                    {errors.note && touched.note ? (
                      <div className="error_message">{errors.note}</div>
                    ) : null}
                  </div> */}
                </section>
              </article>
              <Popup
                ref={ref}
                trigger={<span style={{ height: 0 }}></span>}
                className="popup"
                position={"top left"}
              >
                <div className="pop"> Email or Password is incorrect </div>
              </Popup>
              <article className="buttonsWrapper2">
                <Button
                  className="btn"
                  type="submit"
                  variant="contained"
                  disabled={errors.amount ? true : false}
                  endIcon={spinner ? <CgSpinner className="spinner" /> : null}
                >
                  Generate QR Code
                </Button>
              </article>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
