/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserUtility } from "../../components/utilities/UserUtility";
import TransactionComponent from "../../components/transactionComponent/TransactionComponent";
import UserContext from "../../contextApi/UserContext";
import moment from "moment";
import { useLocation } from "react-router-dom";

export default function Transaction() {
  const [customerHistory, setCustomerHistory] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [passStartDate, setPassStartDate] = useState("");
  const [passEndDate, setPassEndDate] = useState("");
  const [branch, setBranch] = useState("");
  const { handleLoader } = useContext(UserContext);
  const [count, setCount] = useState(1);

  const location = useLocation();
  const liability_date = location.state?.liability_date;

  useEffect(() => {
    liability_date ? getTransactionSettlement() : getTransactionDetails();
  }, [count]);

  const handleCounter = () => {
    if (customerHistory.next !== null && customerHistory.next.length > 10) {
      setCount(count + 1);
    }
  };
  const getTransactionDetails = (counter) => {
    let counterCheck = counter === 1 ? 1 : count;
    let actualStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;
    let actualEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;

    handleLoader(true);
    UserUtility.transactionDetails(
      actualStartDate,
      actualEndDate,
      branch,
      counterCheck,
      false
    )
      .then((res) => {
        if (count !== 1) {
          customerHistory.results.push(...res.results);
          customerHistory.next = res.next;
        } else {
          setCustomerHistory(res);
        }
        handleLoader(false);
      })
      .catch((err) => {
        console.error("error", err);
        handleLoader(false);
      });
  };

  //to get data with liability date "from settlement component"
  const getTransactionSettlement = (counter) => {
    let counterCheck = counter === 1 ? 1 : count;

    handleLoader(true);
    const liability_date_reformat = moment(liability_date).format("YYYY-MM-DD");
    UserUtility.transactionDetails(
      liability_date_reformat,
      liability_date_reformat,
      branch,
      counterCheck,
      true
    )
      .then((res) => {
        if (count !== 1) {
          customerHistory.results.push(...res.results);
          customerHistory.next = res.next;
        } else {
          setCustomerHistory(res);
        }
        handleLoader(false);
      })
      .catch((err) => {
        console.error("error", err);
        handleLoader(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCount(1);
    getTransactionDetails(1);
    setPassStartDate(moment(startDate).format("DD-MM-YYYY"));
    setPassEndDate(moment(endDate).format("DD-MM-YYYY"));
    delete location.state?.liability_date;
  };

  return (
    <div className="transaction_container">
      <form className="transsaction_search" onSubmit={handleSubmit}>
        <section className="search_byDate">
          <h5>From</h5>
          <DatePicker
            selected={startDate}
            dateFormat="dd/ MM/ yyyy"
            className="search_input date"
            placeholderText="DD.MM.YYYY"
            name="start_Date"
            showYearDropdown
            dropdownMode="select"
            onChange={(date) => setStartDate(date)}
          />
          <h5>To</h5>
          <DatePicker
            selected={endDate}
            dateFormat="dd/ MM/ yyyy"
            className="search_input date"
            placeholderText="DD.MM.YYYY"
            name="end_Date"
            showYearDropdown
            dropdownMode="select"
            onChange={(date) => setEndDate(date)}
          />
        </section>
        <h6>Or</h6>
        <section className="search_byBranch">
          <input
            className="search_input branch"
            type="text"
            placeholder="Branch"
            onChange={(e) => setBranch(e.target.value)}
          />
          <Button className="searchButton" type="submit" variant="contained">
            Apply
          </Button>
        </section>
      </form>
      <TransactionComponent
        transactionData={customerHistory}
        handleCounter={handleCounter}
        details={true}
        start_date={passStartDate ? passStartDate : "All"}
        end_date={passEndDate ? passEndDate : "All"}
        liability_date={liability_date}
      />
    </div>
  );
}
