import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./ExportTransactions.scss";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { UserUtility } from "../../components/utilities/UserUtility";
import Popup from "reactjs-popup";

const ExportTransactions = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [branch, setBranch] = useState("");
  //to get profile data
  const location = useLocation();
  const { profile } = location?.state;
  const ref = useRef();
  const openTooltip = () => ref.current.open();

  //export button function
  const exportFunc = (e) => {
    e.preventDefault();
    //start date chosen or starting from beging of Forsa
    const startDate_reformat = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : "2020-01-01";
    //end date chosen or today
    const endDate_reformat = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

    UserUtility.exportTransations(startDate_reformat, endDate_reformat, branch)
      .then(() => {
        openTooltip();
        console.log("done");
      })
      .catch((error) => console.error("export transaction", error));

    //to download excel sheet
    //javascript
    // const token = await localStorage.getItem("merchant_token");
    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", `token ${token}`);

    // var requestOptions = {
    //   method: "GET",
    //   headers: myHeaders,
    //   redirect: "follow",
    // };

    // fetch(
    //   `${
    //     ENDPOINTS.backEnd_url
    //   }merchant/export_merchant_bills/?start_date=${startDate_reformat}&end_date=${endDate_reformat}${
    //     branch !== "" && branch !== "All" ? `&branch=${branch}` : ""
    //   }`,
    //   requestOptions
    // )
    //   .then((response) =>
    //     response.blob().then((blob) => {
    //       let url = window.URL.createObjectURL(blob);
    //       let a = document.createElement("a");
    //       a.href = url;
    //       a.download = "transactions.xlsx";
    //       a.click();
    //     })
    //   )
    //   .catch((error) => console.log("error", error));
  };

  return (
    <main className="export_container">
      <Popup ref={ref} trigger={<span style={{ height: 0 }}></span>} modal>
        <section className="popup_export">
          <span>The sheet has been sent to your Email </span>
        </section>
      </Popup>
      <form>
        <article className="search_byDate">
          <section className="date_wrapper">
            <h5>From</h5>
            <DatePicker
              selected={startDate}
              dateFormat="dd/ MM/ yyyy"
              className="search_input date"
              placeholderText="DD.MM.YYYY"
              name="start_Date"
              showYearDropdown
              dropdownMode="select"
              onChange={(date) => setStartDate(date)}
            />
          </section>
          <section className="date_wrapper">
            <h5>To</h5>
            <DatePicker
              selected={endDate}
              dateFormat="dd/ MM/ yyyy"
              className="search_input date"
              placeholderText="DD.MM.YYYY"
              name="end_Date"
              showYearDropdown
              dropdownMode="select"
              onChange={(date) => setEndDate(date)}
            />
          </section>
          {profile?.is_brand_admin && (
            <FormControl className="select-field">
              <InputLabel id="select-label">All Branches</InputLabel>
              <Select
                size="small"
                value={branch}
                label="Branch"
                onChange={(e) => setBranch(e.target.value)}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {profile?.branches
                  ? profile.branches.map((branch, id) => {
                      return (
                        <MenuItem value={branch.value} key={id}>
                          {branch.label}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          )}

          <Button
            className="export_Btn"
            type="submit"
            variant="contained"
            onClick={(e) => exportFunc(e)}
          >
            Export
          </Button>
        </article>
      </form>
    </main>
  );
};

export default ExportTransactions;
