import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import UserContext from "./contextApi/UserContext";
import Nav from "./pages/navbar/Nav";
import Login from "./pages/login/Login";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import Refund from "./pages/Refund/Refund";
import Transaction from "./pages/transaction/Transaction";
import Verification from "./pages/verification/Verification";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import PasswordChanged from "./pages/passwordChanged/PasswordChanged";
import Otp from "./pages/otp/Otp";
import GeneralView from "./pages/generalView/GeneralView";
import Settlement from "./pages/settlement/settlement";
import LoaderComponent from "./components/loader/LoaderComponent";
import ExportTransactions from "./pages/exportTransac/ExportTransactions";
import PosDetail from "./pages/PosScreens/Detail";
import QrTransaction from "./pages/PosScreens/QrTransaction";
import PosTransactionDetail from "./pages/PosScreens/PosTransactionDetail";

export default function RouteComponent() {
  const user = useContext(UserContext);

  return (
    <Router>
      {user.islogin ? (
        <>
          <LoaderComponent />
          <div className="main_container">
            <div className="nav_container">
              <Nav />
            </div>

            <div className="route_container">
              <Switch>
                <Route exact path="/">
                  <GeneralView />
                </Route>
                <Route exact path="/Refund">
                  <Refund />
                </Route>
                <Route exact path="/Refund/:item">
                  <Otp />
                </Route>
                <Route path="/Transaction">
                  <Transaction />
                </Route>
                <Route path="/LoginPos">
                <Login/>
                </Route>
                {/* <Route path="/Settlement">
                  <Settlement />
                </Route> */}
                <Route path="/Pos/detail">
                  <PosDetail />
                </Route>

                <Route path="/Pos/transaction">
                  <QrTransaction />
                </Route>

                <Route path="/Pos/transactions/:id">
                  <PosTransactionDetail />
                </Route>

                <Route path="/Export">
                  <ExportTransactions />
                </Route>

              </Switch>
            </div>
          </div>
        </>
      ) : (
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/ForgotPassword">
            <ForgotPassword />
          </Route>
          <Route path="/Verification">
            <Verification />
          </Route>
          <Route path="/ResetPassword">
            <ResetPassword />
          </Route>
          <Route path="/PasswordChanged">
            <PasswordChanged />
          </Route>
        </Switch>
      )}
    </Router>
  );
}
