const Url = "https://forsa-staging.bit68.com/api/v1/";

// const Url = "https://backend.forsaegypt.com/api/v1/";

export const ENDPOINTS = {
  backEnd_url: Url,
  generalView: {
    url: (pageNumber, search_by_liability_date) =>
      `${Url}merchant/transaction-details/?page=${pageNumber}&search_by_liability_date=${search_by_liability_date}`,
    method: "GET",
  },
  transactionDetails: {
    url: (start_date, end_date, branch, pageNumber, search_by_liability_date) =>
      start_date && end_date && branch
        ? `${Url}merchant/transaction-details/?start_date=${start_date}&page=${pageNumber}&end_date=${end_date}&branch=${branch}&search_by_liability_date=${search_by_liability_date}`
        : start_date && end_date
        ? `${Url}merchant/transaction-details/?start_date=${start_date}&page=${pageNumber}&end_date=${end_date}&search_by_liability_date=${search_by_liability_date}`
        : branch
        ? `${Url}merchant/transaction-details/?branch=${branch}&page=${pageNumber}&search_by_liability_date=${search_by_liability_date}`
        : `${Url}merchant/transaction-details/?&page=${pageNumber}&search_by_liability_date=${search_by_liability_date}`,
    method: "GET",
  },
  settlement: {
    url: `${Url}merchant/settlement/`,
    method: "GET",
  },
  refund_transaction_view_byId: {
    url: (userId) =>
      `${Url}merchant/refund_transaction_view/?national_id=${userId}`,
    method: "GET",
  },
  refund_transaction_view_byPhone: {
    url: (userPhone) =>
      `${Url}merchant/refund_transaction_view/?phone=${userPhone}`,
    method: "GET",
  },
  otpToUser: {
    url: `${Url}merchant/refund/request_otp/`,
    method: "POST",
  },
  otpFromUser: {
    url: `${Url}merchant/refund/invoke/`,
    method: "POST",
  },
  login: {
    url: `${Url}merchant/auth/`,
    method: "POST",
  },
  forgetPassword: {
    url: `${Url}merchant/auth/forget_password/create/`,
    method: "POST",
  },
  verificationCode: {
    url: `${Url}merchant/auth/forget_password/submit/otp/`,
    method: "POST",
  },
  resetPassword: {
    url: `${Url}merchant/auth/forget_password/submit/password/`,
    method: "POST",
  },
  merchantData: {
    url: `${Url}merchant/profile/`,
    method: "GET",
  },
  exportTransactions: {
    url: (start_date, end_date, branch) =>
      `${Url}merchant/export_merchant_bills/?start_date=${start_date}&end_date=${end_date}${
        branch !== "" && branch !== "All" ? `&branch=${branch}` : ""
      }`,
    method: "GET",
  },
  // exportTransactions: {
  //   url: (start_date, end_date) =>
  //     //branch ? `${Url}merchant/export_merchant_bills/?branch=${branch}`
  //     // : `${Url}merchant/export_merchant_bills/?start_date=${start_date}&end_date=${end_date}`,
  //     `${Url}merchant/export_merchant_bills/?start_date=${start_date}&end_date=${end_date}`,
  //   method: "GET",
  // },

  // Pos
  pos_login: {
    url: `${Url}pos/auth/web/`,
    method: "POST",
  },
  qr: {
    url: `${Url}pos/generate_qr/`,
    method: "POST",
  },
  posTrans: {
    url: (params) => params ? `${Url}pos/get_purchase_list/${params}` : `${Url}pos/get_purchase_list/`,
    method: "GET",
  },
  confirmTrans: {
    url: (id) => `${Url}pos/confirm_transaction/${id}/`,
    method: "PUT",
  },
};
